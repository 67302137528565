var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-bar mb-4 d-flex align-items-center"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.menuModal",modifiers:{"menuModal":true}}],staticClass:"btn btn-outline-dark mr-auto border-0",style:({ color: _vm.customOptions.menuColor })},[_c('b-icon-list'),_vm._v(" Menu ")],1),(_vm.dataReady && _vm.firebaseReady)?_c('app-roomLink',{staticClass:"d-none d-sm-block",attrs:{"monetizedByUser":_vm.monetizedByUser,"routeRoomID":_vm.$route.params.roomID,"color":_vm.customOptions.menuColor},on:{"roomMonetized":function($event){return _vm.$emit('roomMonetized', true)}}}):_vm._e(),_c('b-modal',{attrs:{"id":"menuModal","title":_vm.customOptions.gameTitle ? _vm.customOptions.gameTitle : 'Menu',"hide-footer":""}},[_c('b-container',[_c('div',{staticClass:"row menu-row"},[_c('b-button',{staticClass:"border-0 btn-lg btn-block",on:{"click":[function($event){_vm.copyLinkToClipboard(); _vm.closeMenu();},function($event){return _vm.$bvToast.show('copyToast')}]}},[_c('b-icon-link45deg'),_vm._v(" Copy URL ")],1)],1),(_vm.roomInfo)?_c('div',{staticClass:"row menu-row"},[((_vm.tempExtensionData['journalEntries'] || _vm.tempExtensionData['multiEditableLists'] || _vm.tempExtensionData['editableList'] || _vm.tempExtensionData['plusMinus']))?_c('app-downloadExtensionData',{attrs:{"extensionData":_vm.roomInfo.extensionData,"gameTitle":_vm.customOptions.gameTitle}}):_vm._e()],1):_vm._e(),_vm._t("default")],2),(_vm.roomInfo.extensionData && _vm.roomInfo.extensionData.interruptsInMenu)?_c('div',[_c('hr',{staticClass:"mb-4"}),(
          _vm.tempExtensionData['interrupts'] &&
          (!_vm.tempExtensionData.interruptsFirstVisible ||
            _vm.tempExtensionData.interruptsFirstVisible <=
              _vm.roomInfo.currentCardIndex) &&
          (!_vm.tempExtensionData.interruptsLastVisible ||
            _vm.tempExtensionData.interruptsLastVisible >
              _vm.roomInfo.currentCardIndex)
        )?_c('app-interrupts',{staticClass:"extension",attrs:{"extensionList":this.tempExtensionData,"currentInterrupt":_vm.roomInfo.extensionData.currentInterrupt,"menuLocation":true},on:{"process-extension-update":function($event){return _vm.processExtensionUpdate($event)}}}):_vm._e()],1):_vm._e(),((_vm.customOptions.modalOneLabel || _vm.customOptions.modalTwoLabel || _vm.customOptions.modalThreeLabel || _vm.customOptions.modalFourLabel || _vm.customOptions.modalFiveLabel))?_c('div',{},[_c('hr',{staticClass:"mb-4"}),_vm._l((_vm.modalNumberList),function(modalNumber){return _c('div',{key:modalNumber},[(_vm.customOptions['modal' + modalNumber + 'Label'])?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal' + modalNumber),expression:"'modal' + modalNumber"}],staticClass:"btn-block btn-lg my-1",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.closeMenu();}}},[_vm._v(" "+_vm._s(_vm.customOptions['modal' + modalNumber + 'Label'])+" ")]):_vm._e()],1)})],2):_vm._e(),_c('div',{staticClass:"row menu-row mt-4"},[_c('a',{attrs:{"href":"https://storysynth.org","target":"_blank"}},[_vm._v("Powered by Story Synth")])])],1),_vm._l((_vm.modalNumberList),function(modalNumber){return _c('b-modal',{key:modalNumber,attrs:{"title":_vm.customOptions['modal' + modalNumber + 'Label'],"id":'modal' + modalNumber,"hide-footer":""}},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.customOptions['modal' + modalNumber + 'Text']),expression:"customOptions['modal' + modalNumber + 'Text']"}],staticClass:"d-block text-left"})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }